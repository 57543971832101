<template>
  <div class="aside-menu">
    <el-menu
      :default-active="$route.path"
      background-color="#272B38"
      text-color="#fff"
      router
      active-text-color="#fff"
      class="el-menu-vertical-demo">
      <el-menu-item index="/institution/list">
        <i class="iconfont icon-a-1caidan-1jigouguanli"></i>
        <span slot="title">机构管理</span>
      </el-menu-item>
      <el-menu-item index="/institution/mode">
        <i class="iconfont icon-a-1caidan-2jianchabiaomoban"></i>
        <span slot="title">检查表模板</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "AsideMenu",
}
</script>

<style lang="less" scoped>
.aside-menu{
  padding-top: 16px;
}
.iconfont{
  font-size: 24px;
  padding-right: 8px;
}
.el-menu-item{
  width:200px;
}
/deep/ .el-menu-item.is-active {
  background-color: #409dff !important;
}
</style>
