<template>
  <div>
    <el-container class="layout-container">
      <el-header>
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <AsideMenu></AsideMenu>
        </el-aside>
        <el-container class="right-content" style="background: #F0F3FB;height: calc(100vh - 64px);width: 100%;">
          <el-main>
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "views/layout/Header";
import AsideMenu from "views/layout/AsideMenu";
export default {
  components:{
    Header,AsideMenu
  }
}
</script>

<style scoped lang="scss">
.layout-container {
  width: 100%;
  height: 100%;
}
.el-header {
  padding: 0;
  height:64px!important;
}
.el-aside {
  background-color: #272B38;
  color: #fff;
  height: calc(100vh - 64px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.el-main{
  background: #fff;
  padding: 16px;
  margin: 9px 16px 19px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.10);
}
.right-content,.el-main{
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

</style>
